import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Input as StyledInput, InputButton } from "./styles";

const Input = ({ buttonText, buttonType, ...rest }) => {
  return (
    <Fragment>
      <StyledInput {...rest} />
      {buttonText && <InputButton type={buttonType}>{buttonText}</InputButton>}
    </Fragment>
  );
};

Input.propTypes = {
  buttonText: PropTypes.string,
  buttonType: PropTypes.oneOf(["button, submit, reset"])
};

Input.defaultProps = {
  buttonType: "button"
};

export default Input;
