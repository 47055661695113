import styled from "@emotion/styled";
import colors from "../../styles/colors";

export const Textarea = styled.textarea`
  border: 1px solid ${colors.greyLightest};
  padding: 1rem;
  border-radius: .5rem;
  width: 100%;
  resize: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.grey};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.grey};
  }

  &:hover { 
    border: 1px solid ${colors.greyLight};
    transition: 0.5s all;
  }

  &:focus {
    border: 1px ${colors.grey} solid;
    outline: none;
  }
`;
