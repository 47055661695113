import React, { useState } from "react";
import {
  ResponseMessage,
  Container,
  MaxWidth,
  Input,
  Form,
  Textarea,
  Header,
  Description,
  MainWrapper,
} from "./styles";
import Button from "../../components/Button";
import Typography from "../../components/Typography";
import axios from "axios";
import * as qs from "query-string";

const FORM_NAME = "Request a Demo";

const REQUIRED_FIELDS = ["first_name", "last_name", "email"];

const Demo = ({ location }) => {
  const initialForm = {
    "form-name": FORM_NAME,
    first_name: "",
    last_name: "",
    company: "",
    email: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState("");

  const onFormChange = (e) => {
    const {
      target: { name, value },
    } = e;

    if (errors[name]) {
      setErrors({ ...errors, [name]: false });
    }
    if (responseMessage) {
      setResponseMessage("");
    }

    setFormData({ ...formData, [name]: value });
  };

  const hasErrors = (currentErrors) =>
    Object.values(currentErrors).some((val) => val === true);
  const hasData = (name) => formData[name] && formData[name].length;

  const updateErrors = () => {
    const currentErrors = REQUIRED_FIELDS.reduce(
      (accum, name) => ({ ...accum, [name]: !hasData(name) }),
      {},
    );

    setErrors(currentErrors);

    return hasErrors(currentErrors);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (updateErrors()) return;

    const payload = {
      url: location.pathname,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(formData),
    };

    axios(payload)
      .then(() => {
        setFormData(initialForm);
        setResponseMessage("🎉 We'll be in touch shortly!");
      })
      .catch(() => {
        setResponseMessage(
          "Oops! Looks like we had trouble submitting your information.",
        );
      });
  };

  const {
    first_name: firstName,
    last_name: lastName,
    email,
    company,
    message,
  } = formData;
  const {
    first_name: firstNameError,
    last_name: lastNameError,
    email: emailError,
  } = errors;

  return (
    <MainWrapper>
      <Container shrinkTop>
        <MaxWidth>
          <Header>
            <Typography variant="h1">Join the waitlist</Typography>
          </Header>
          <Description>
            Outline is currently in private beta with select customers. We're
            always looking to build new relationships — fill out the form below
            and we'll be in touch!
          </Description>
          <Form
            name={FORM_NAME}
            method="POST"
            data-netlify="true"
            onSubmit={onSubmit}
          >
            <Input
              key={"demo-first-name-input"}
              name={"first_name"}
              onChange={onFormChange}
              value={firstName}
              placeholder={"First name *"}
              error={firstNameError}
            ></Input>
            <Input
              key={"demo-last-name-input"}
              name={"last_name"}
              onChange={onFormChange}
              value={lastName}
              placeholder={"Last name *"}
              error={lastNameError}
            ></Input>
            <Input
              key={"demo-email-input"}
              name={"email"}
              value={email}
              onChange={onFormChange}
              placeholder={"Email *"}
              error={emailError}
            ></Input>
            <Input
              key={"demo-company-input"}
              name={"company"}
              value={company}
              onChange={onFormChange}
              placeholder={"Company"}
            ></Input>
            <Textarea
              key={"demo-message-textarea"}
              onChange={onFormChange}
              name="message"
              value={message}
              placeholder="Message (optional)"
            />
            <ResponseMessage>{responseMessage}</ResponseMessage>
            <Button>Submit</Button>
          </Form>
        </MaxWidth>
      </Container>
    </MainWrapper>
  );
};

export default Demo;
