import styled from "@emotion/styled";
import colors from "../../styles/colors";

export const InputButton = styled.button`
  color: ${colors.white};
  background: ${colors.primary};
  font-size: 0.75rem;
  font-weight: 500;
  border-radius: 0.25rem;
  padding: 0rem 1.5rem;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  outline: none;

  &:focus {
    border: none;
    outline: none;
  }

  &:active {
    border: none;
    outline: none;
  }

  &:hover {
    cursor: pointer;
    background: ${colors.primaryDark};
    transition: ease-in-out 0.2s;
  }
`;

export const Input = styled.input`
  border: 1px solid ${colors.grey};
  border: ${props => getInputBorder(props)};
  border-radius: .5rem;
  padding: 1rem 2rem 1rem 1rem;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.grey};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.grey};
  }

  &:hover { 
    border: 1px solid ${colors.greyLight};
    transition: 0.5s all;
  }

  &:focus {
    border: 1px ${colors.grey} solid;
    outline: none;
  }

  &:active {
    outline: none;
  }
`;

const getInputBorder = ({ error }) => {
  if (error) {
    return `1px solid ${colors.red}`;
  }

  return `1px solid ${colors.greyLightest}`;
};
