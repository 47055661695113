import styled from "@emotion/styled";
import BaseInput from "../../components/Input";
import BaseTextarea from "../../components/Textarea";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import Section from "../../components/Section";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Container = styled(Section)`
  text-align: center;
`;

export const MaxWidth = styled.div`
  max-width: 500px;
`;

export const Input = styled(BaseInput)`
  margin-bottom: 1rem;
`;

export const Description = styled.body`
  color: ${colors.greyDark};
`;
export const Form = styled.form`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  justify-content: center;
  flex-direction: column;
  background-color: ${colors.white};
  padding: 2rem;
  border-radius: 16px;
  min-width: 500px;
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.07);

  @media (max-width: ${breakpoints.small}) {
    padding: 0;
    box-shadow: none;
    min-width: 0;
  }
`;

export const Header = styled.div`
  colors: ${colors.black};
`;

export const Textarea = styled(BaseTextarea)`
  margin-bottom: 1rem;
  height: 6rem;
`;

export const ResponseMessage = styled.div`
  margin: 0.5rem 0rem;
`;
